import React, { Component } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import parseHeading from '../utils/parseHeading';
import Image from './Image';
import media from '../utils/media';

const Wrapper = styled.div`
  ${tw``}

  .right {
    max-width: 474px;
    ${media.greaterThan('sm')`
      max-width: 849px;
      `}
  }

  .img-container {
    height: 60px;
    ${media.greaterThan('sm')`
      height: 73px;
    `}

    img {
      max-width: 120px;
      max-height: 25px;
      object-fit: contain;
      ${media.greaterThan('sm')`
        max-width: 169px;
        max-height: 32px;
      `}
    }
  }
`;

const Quote = styled.blockquote`
  font-weight: 600;
  letter-spacing: -0.035;
  line-height: 1.23;
`;

export default class Testimonial extends Component {
  render() {
    const { backgroundColour, cite, logo, quote } = this.props.data;

    return (
      <Wrapper className={`pad-tb--sm lg:pad-tb text-white text-center sm:text-left  ${backgroundColour}`}>
        <div className="container">
          <div className="flex flex-col sm:flex-row justify-center -ml-12">
            <div className="left pl-12">
              {logo && <div className="hidden sm:block img-container mb-3" />}
              <svg width="48" height="40" xmlns="http://www.w3.org/2000/svg" className="mx-auto sm:mx-0 mb-8 sm:mb-0 transform scale-90 sm:scale-100">
                <path d="M20.445 39.25V20.195H9.265L20.825.25h-8.51L.5 20.195V39.25h19.945zm26.677 0V20.195H35.943L47.503.25h-8.638L27.178 20.195V39.25h19.944z" fill="#0D0D0D" />
              </svg>
            </div>

            <div className="pl-12">
              <div className="right mx-auto sm:mx-0">
                {logo && (
                  <div className="img-container">
                    <Image data={logo} className="mx-auto sm:ml-0" />
                  </div>
                )}

                <Quote className={`text-42px mb-10 lg:text-${quote.length <= 230 ? '56px' : '48px'}`} dangerouslySetInnerHTML={{ __html: parseHeading(quote, 'text-black underline') }} />

                <cite className="not-italic block md:mb-3">
                  <div className="text-20px sm:text-24px font-medium">{cite.name}</div>
                  <div className="font-medium">{cite.role}</div>
                </cite>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
